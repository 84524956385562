

h5{
  font-size: 15px;
  font-weight: 700;
  margin-top: 20px;
}

#SAP_warning{
  display: inline-block;
  height: 20px;
  width: 20px;
}

#SAP_status_message{
  font-size: 12px;
}

.status_alert.valid #SAP_status_message {
  font-size: 14px;
}

.status_alert.valid #SAP_warning{
  display: none;
}

#SAP_warning svg{
  fill: #cf0a0a;
}

.alert{
  padding: 6px 8px 0px 8px;
  margin-bottom: 8px;
}
.alert h6 {
  font-size: 14px;
  margin-bottom: 2px;
}

.alert p {
  font-size: 14px;
  margin-bottom: 8px;   
} 

.status_alert{
  background-color: #f1f1f1; 
  border-radius: 4px; 
  border: #a1a1a1 1px solid;
  min-height: 44px;
}

.status_alert.invalid{
  background-color: #f9d7da; 
  border-radius: 4px; 
  border: #dc3545 1px solid;
}

.status_alert.valid{
  background-color: #d7f9de; 
  border: #119b18 1px solid;
}

.debug_table{
  width: 100%;
}

.debug_table td{
  color: #000;
  font-size: 11px;
}

.warnings_container{
  padding-bottom: 15px;
  border-bottom: #eee 1px solid;
}


